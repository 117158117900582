import type { ethers } from 'ethers'
import { DECIMALS } from 'constants/common'
import { ETH } from 'constants/network'
import { env } from 'env.client'
import { RecentTransactionsListItemExitStatus as TxItemExitStatus } from 'generated/reactQueryClient'

export const getFormattedNumber = (value: string | number) =>
  Number(value).toLocaleString(undefined, { maximumFractionDigits: 20 })

export const weiToEth = (value: string | number, decimals?: string | number) =>
  Number(value) / 10 ** Number(decimals ?? DECIMALS)

export const calculateGasFees = ({ gasUsed = 0, gasPrice = 0 }) => weiToEth(gasUsed * gasPrice)

export const isWstETHToken = (tokenName: string) =>
  ['testnet', 'garfield-testnet', 'mainnet'].includes(env.NEXT_PUBLIC_NETWORK) &&
  tokenName === 'wstETH'

export const isZrcToken = (tokenName: string) =>
  env.NEXT_PUBLIC_NETWORK === 'mainnet' && tokenName === 'ZRC'

export const isLsETHToken = (tokenName: string) =>
  env.NEXT_PUBLIC_NETWORK === 'mainnet' && tokenName === 'LsETH'

export const isUSDTToken = (tokenName: string) =>
  env.NEXT_PUBLIC_NETWORK === 'mainnet' && tokenName === 'USDT'

// TODO: Remove this helper and revert to previous state when we will get USDC token symbol as 'USDC' instead of 'USDC.e' || 'tUSDC.eth' and WBTC token symbol as 'WBTC' instead of 'WBTC.e'.
// https://sudo-labs.slack.com/archives/C04AD3Q8JV7/p1732154450935959?thread_ts=1732104930.711369&cid=C04AD3Q8JV7
export const getAssetSymbol = (symbol?: string) => {
  if (!symbol) {
    return ETH.symbol
  }

  if (symbol === 'WBTC.e') {
    return 'WBTC'
  }

  if (symbol === 'USDC.e' || symbol === 'tUSDC.eth') {
    return 'USDC'
  }

  return symbol
}

export const getWatchAssetSymbol = (symbol: string) => {
  if (symbol === 'WBTC' && env.NEXT_PUBLIC_NETWORK === 'mainnet') {
    return 'WBTC.e'
  }

  if (symbol === 'USDC' && env.NEXT_PUBLIC_NETWORK === 'mainnet') {
    return 'USDC.e'
  }

  if (symbol === 'USDC' && ['testnet', 'garfield-testnet'].includes(env.NEXT_PUBLIC_NETWORK)) {
    return 'tUSDC.eth'
  }

  return symbol
}

export const doesTransactionHaveValidExitStatus = (exitStatus?: TxItemExitStatus) =>
  [
    TxItemExitStatus.ready_to_relay,
    TxItemExitStatus.ready_to_prove,
    TxItemExitStatus.in_challenge_period,
    TxItemExitStatus.waiting,
  ].includes(exitStatus ?? '')

export const isAddressEOA = async (address: string, provider: ethers.JsonRpcProvider) => {
  const code = await provider.getCode(address)
  // with eip7702 an EOA can delegate to contract address designated by prefix 0xef0100
  if (code === '0x' || code.toLowerCase().startsWith('0xef0100')) {
    return true
  }
  return false
}
