import React from 'react'
import { RiCheckFill, RiCloseFill, RiLoopRightLine, RiTimerLine } from 'react-icons/ri'
import { env } from 'env.client'
import {
  TransactionListItemExitStatus as TxExitStatus,
  TransactionListItemStatus as TxStatus,
} from 'generated/reactQueryClient'

export const ERROR_CODES = {
  tooManyRequests: 429,
}

export const ERROR_MESSAGES = {
  tooManyRequests: 'Too many requests. You have reached our rate limit. Try again in one minute.',
}

export const PAGE_LENGTH = 25 as const
export const DEFAULT_VALUE = '0'

export const EXPECTED_TRANSACTION_BUTTON_WAITING_TIME_MAINNET_IN_HOURS = 6 as const
export const EXPECTED_TRANSACTION_BUTTON_WAITING_TIME_OTHER_NETWORKS_IN_HOURS = 1 as const

export const TXN_STATUS_VARIANTS = {
  [TxStatus.successful]: 'success',
  [TxStatus.failed]: 'failed',
  [TxStatus.pending]: 'critical',
  [TxStatus.replaced]: 'information',
} as const

export const TXN_STATUS_ICONS = {
  [TxStatus.successful]: <RiCheckFill size={16} />,
  [TxStatus.failed]: <RiCloseFill size={16} />,
  [TxStatus.pending]: <RiTimerLine size={16} />,
  [TxStatus.replaced]: <RiLoopRightLine size={16} />,
} as const

export const EXIT_TXN_STATUS_LABELS = {
  [TxExitStatus.waiting]: 'Waiting',
  [TxExitStatus.relayed]: 'Relayed',
  [TxExitStatus.ready_to_relay]: 'Ready to claim',
  [TxExitStatus.in_challenge_period]: 'In claiming period',
  [TxExitStatus.ready_to_prove]: 'Ready to claim',
}

export const TRANSACTION_TYPES = {
  l1l2: 'l1l2',
  l2l1: 'l2l1',
} as const

export const TRANSACTION_TYPE_LABELS = {
  [TRANSACTION_TYPES.l1l2]: 'L1 → L2',
  [TRANSACTION_TYPES.l2l1]: 'L2 → L1',
}

export const EXIT_TRANSACTION_TYPE = 'l2l1' as const

export const COLOR_MODE = {
  LIGHT: 'light',
  DARK: 'dark',
} as const

export const NOT_APPLICABLE = 'N/A' as const

export const DECIMALS = 18

export const WEB3_RESPONSE_ERROR_PREFIX = 'execution reverted: ' as const

export const NON_STANDARD_BRIDGE_TOKENS = {
  wstETH: 'wstETH',
}

export const APP_URL =
  env.NEXT_PUBLIC_NETWORK === 'mainnet'
    ? 'https://bridge.zircuit.com'
    : `https://bridge.${env.NEXT_PUBLIC_NETWORK}.zircuit.com`

export const PESSIMISTIC_GAS_ESTIMATE_MULTIPLIER = 125n as const // 1.25x

export const ASSETS_POLLING_INTERVAL = 60_000 as const // 1 minute

export const RECENT_TRANSACTIONS_LIMIT = 5 as const
export const RHINO_BRIDGE_LINK =
  'https://app.rhino.fi/bridge?chain=ETHEREUM&token=ETH&chainOut=ZIRCUIT' as const

// Common multicall3 address used across many networks.
export const MULTICALL3_ADDRESS = '0xcA11bde05977b3631167028862bE2a173976CA11' as const
