import { accordionAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'
import { mode } from '@chakra-ui/theme-tools'

const helpers = createMultiStyleConfigHelpers(accordionAnatomy.keys)

const variants = {
  list: helpers.definePartsStyle((props) => ({
    root: {
      backgroundColor: mode('white', 'grey10')(props),
      borderRadius: 10,
    },
    container: {
      borderTop: '1px solid',
      borderX: '1px solid',
      borderBottom: 'unset',
      _first: {
        borderTopRadius: 10,
      },
      _last: {
        borderBottom: '1px solid',
        borderBottomRadius: 10,
      },
      borderColor: mode('grey06!important', 'darkGrey06!important')(props),
    },
    button: {
      p: 4,
      alignItems: 'flex-start',
    },
  })),
}

export const Accordion = helpers.defineMultiStyleConfig({ variants })
