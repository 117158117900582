import { ethers } from 'ethers'
import { isAddressEOA } from './common'

export const isMultisigWallet = async (address: string, provider: ethers.JsonRpcProvider) => {
  try {
    const isEOA = await isAddressEOA(address, provider)

    if (isEOA) {
      return false
    }

    // Gnosis Safe ABI method to check for multisig properties
    const safeABI = ['function getOwners() view returns (address[])']

    const contract = new ethers.Contract(address, safeABI, provider)
    // Call `getOwners()` to verify if it's a Gnosis Safe wallet
    await contract.getOwners()
    return true
  } catch {
    return false
  }
}
